<template>
  <section id="order-list">
    <!-- <div class="content__header">{{ title }}</div> -->
    <div class="b-table__wrapper">
      <documents-header
        page_type="order"
        @date_range="updateDateRange"
      />
      <div class="b-table__content">
        <documents-table-filter
          ref="=doc_table_filter"
          table_name="orders-docs"
          placeholder="Поиск"
          @handler_pagination="handlerSearch"
          @clear="clearSearchStr"
        />
        <resizable-table
          class="my-class"
          :inside_card="false"
          table_name="order-list"
          empty_text="Ничего не найдено"
          :items="items"
          :default_fields="fields"
          :busy="$apollo.queries.OrderHeads.loading"
          @row-click="rowClicked"
        >
          <template #head_id>
            <b-checkbox
              v-model="select_all"
              @change="setSelected"
            />
          </template>

          <template #body_id="{ item, value }">
            <div
              class="b-status"
              :class="{ success: item.fixed }"
            ></div>
            <div @click.stop>
              <b-checkbox
                v-model="selected"
                :value="value"
              />
            </div>
          </template>

          <template #body_date="{ item }">
            <div class="b-date text-left">{{ item.number }}</div>
            <div class="b-date date mt-1 table-substr text-left">
              {{ item.date ? formatDate(item.date) : '' }}
            </div>
          </template>

          <template #body_supplier="data">
            <div class="b-supplier name cursor text-left">
              {{
                data.item.supplier?.full_name
                  ? data.item.supplier?.full_name
                  : data.item.supplier?.name
                  ? data.item.supplier?.name
                  : 'Не указан'
              }}
            </div>
            <div class="b-supplier info table-substr text-left">
              ИНН/КПП:
              <span class="ml-2">
                {{ data.item.supplier?.inn ? data.item.supplier.inn : '–' }}/
                {{ data.item.supplier?.kpp ? data.item.supplier.kpp : '–' }}
              </span>
            </div>
          </template>

          <template #body_fixed="{ item }">
            <b-badge :class="{ success: item.fixed }">
              {{ item.fixed ? 'Проведен' : 'Черновик' }}
            </b-badge>
          </template>

          <template #body_sum="{ value }">
            <span class="d-flex float-right"> {{ formatPrice(value) }} ₽ </span>
          </template>

          <template #body_new_sum="{ value }">
            <span class="d-flex float-right"> {{ formatPrice(value) }} ₽ </span>
          </template>

          <template #body_retail_sum="{ item }">
            <span class="d-flex float-right"> {{ item.retail_sum }} ₽ </span>
          </template>

          <template #body_supply_date="{ item }">
            <span class="d-flex float-left">
              {{ formatDate(item.supply_date) }}
            </span>
          </template>

          <template #body_owner="{ item }">
            <span class="d-flex float-left">
              {{ item.owner?.last_name ?? '' }}
              {{ item.owner?.first_name ?? '' }}
            </span>
          </template>

          <template #body_status="{ item }">
            <div
              v-if="item.status !== 'going'"
              style="width: 100%; display: flex; justify-content: center"
            >
              <div
                class="collumn-set"
                style="border: 1px solid #00cb91; border-radius: 3px; padding: 4px 12px; color: #00cb91"
              >
                Отправлен
              </div>
            </div>
            <div
              v-else
              style="width: 100%; display: flex; justify-content: center"
            >
              <div
                class="collumn-set"
                style="border: 1px solid #888888; border-radius: 3px; padding: 4px 12px; color: #888888"
              >
                Не отправлен
              </div>
            </div>
          </template>

          <template #body_total="{ item }">
            <div style="display: flex; flex-direction: row; justify-content: space-around; align-items: center">
              <span style="flex: 1">{{ item.sum }} </span>
              <span style="flex: 1">{{ item.retail_sum }} </span>
            </div>
          </template>
        </resizable-table>
        <table-navbar
          style="margin: -25px 0 0"
          doc_type="order"
          :items="selected"
          :clear_selected="clearSelected"
          items_name="documents"
          @remove_items="removeHeads"
          @print="printHeads"
          @createIncoming="createIncoming"
        />
      </div>
    </div>

    <pagination

      :total="total"
      style="margin-bottom: 0"
      table_name="orders-docs"
      @change_pagination="handlerPagination"
    />
  </section>
</template>

<script>
  import DocumentsHeader from '@/views/operational-processes/components/DocumentsHeader'
  import DocumentsTableFilter from '@/views/operational-processes/components/DocumentsTableFilter'
  import ResizableTable from '@/components/ResizableTable'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapActions, mapGetters } from 'vuex'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import { OrderHeadModel } from '@/views/order/model/order-head.model'
  import { http } from '@/http/http'
  import { print_html } from '@/utils/print'

  export default {
    name: 'Orders',

    apollo: {
      OrderHeads: {
        query: require('../gql/orderHeads.graphql'),
        deep: true,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch.id,
              pagination: {
                skip: this.getPagination?.['orders-docs']?.skip,
                take: this.getPagination?.['orders-docs'].take,
                search: this.getPagination?.['orders-docs'].search
              }
            }
          }
        },
        result({ data }) {
          this.selected = []
          if (!data) return
          this.items = data?.OrderHeads?.documents?.map((el) => new OrderHeadModel(el))
          this.total = data?.OrderHeads?.total ?? 0
        }
      }
    },

    components: {
      TableNavbar,
      Pagination,
      ResizableTable,
      DocumentsTableFilter,
      DocumentsHeader
    },
    data() {
      return {
        title: this.$route.meta.title,
        period: {
          begin: null,
          end: null
        },
        select_all: false,
        selected: [],
        fields: [
          { key: 'id', label: '', class: 'id', width: 40, checked: true },
          { key: 'date', label: 'Дата', width: 75, checked: true },
          { key: 'supplier', label: 'Поставщик', width: 200, checked: true },
          { key: 'fixed', label: 'Статус', width: 125, checked: true },
          { key: 'status', label: 'Состояние', width: 180, checked: true },
          { key: 'owner', label: 'Менеджер', width: 145, checked: true },
          { key: 'supply_date', label: 'Поставка', width: 120, checked: true },
          {
            key: 'total',
            label: 'Итого, ₽',
            width: 180,
            class: 'children',
            checked: true,
            children: [
              { key: 'sum', label: 'В закупочных', checked: true },
              { key: 'retail_sum', label: 'В розничных', checked: true }
            ]
          },
          { key: 'comment', label: 'Комментарий', width: 200, checked: true }
        ],
        items: [],
        total: 0
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getPagination: 'settings/getPagination'
      })
    },

    watch: {
      selected(newVal, oldVal) {
        if (newVal.length < oldVal.length) this.select_all = false
      }
    },

    methods: {
      ...mapActions({ setPagination: 'settings/set_pagination' }),
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      updateDateRange(date) {
        this.period = {
          begin: date[0],
          end: date[1]
        }
      },
      handlerSearch(search) {
        this.setPagination({
          ...this.getPagination,
          ['orders-docs']: {
            ...this.getPagination?.['orders-docs'],
            search
          }
        })
      },
      clearSearchStr() {
        this.setPagination({
          ...this.getPagination,
          ['orders-docs']: {
            ...this.getPagination?.['orders-docs'],
            search: ''
          }
        })
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) {
          this.setPagination({
            ...this.getPagination,
            ['orders-docs']: {
              ...this.getPagination?.['orders-docs'],
              skip: Number((current_page - 1) * take)
            }
          })
        }
        if (take) {
          this.setPagination({
            ...this.getPagination,
            ['orders-docs']: {
              ...this.getPagination?.['orders-docs'],
              take
            }
          })
        }
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      rowClicked({ id }) {
        this.$router.push({
          name: 'operation-process.overhead.order.document.edit',
          params: { id }
        })
      },
      async removeHeads(items) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/RemoveHeads.graphql'),
          variables: {
            input: { ids: items }
          }
        })

        if (data?.RemoveOrderHeads?.status) {
          this.$apollo.queries.OrderHeads.refresh()
          this.selected = []
        }
      },
      async setSelected() {
        if (!this.select_all) this.selected = []
        else this.selected = this.items.map((el) => el.id)
      },
      clearSelected() {
        this.selected = []
      },
      async createIncoming() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/CreateIncomingFromOrder.graphql'),
          variables: {
            input: {
              id: this.selected[0]
            }
          }
        })
        this.$router.push({
          name: 'operation-process.overhead.incoming.document.edit',
          params: { id: data?.CreateIncomingFromOrder?.id }
        })
      },
      async printHeads() {
        for (let head of this.selected) {
          try {
            const { data } = await http.get(`templates/order?order=${head}`)
            if (data) {
              print_html(data)
            } else {
              this.$noty.error('Ошибка печати')
            }
          } catch (e) {
            console.error(e)
            this.$noty.error('Ошибка печати')
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-substr {
    font-size: 12px;
    color: #888888;
  }

  // .success {
  //   background: #00cb91;
  // }

  #order-list {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .b-table__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    //width: 0 !important;
  }

  .b-table__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  :deep th.children {
    div {
      justify-content: flex-start !important;
      display: flex;
      float: left;
    }
    :deep .td-inner {
      width: 100%;
      padding-right: 30px;
      display: flex;
      justify-content: space-between !important;
    }
  }

  :deep td.text-nowrap {
    position: relative;
    .b-status {
      width: 2px !important;
      height: 32px !important;
      position: absolute;
      left: 0px;
      top: 50%;
      background: #bcbcbc;
      border-radius: 0px 1px 1px 0px;
      transform: translate(0%, -50%);

      &.success {
        background: #00cb91;
      }
    }
  }
  :deep th.cursor:not(:nth-child(1)):not(:nth-child(8)):not(:nth-child(5)) {
    div {
      justify-content: flex-start !important;
      display: flex;
      float: left;
    }
    .td-inner {
      width: 100%;
      padding-right: 30px;
      display: flex;
      justify-content: space-between !important;
    }
  }

  :deep .badge {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef !important;
    color: #888888;

    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
  }

  :deep td.text-nowrap {
    position: relative;

    .custom-checkbox {
      width: 100%;
      margin-right: 10px;
    }

    &.custom_checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .b-statuses {
      width: 2px !important;
      height: 32px !important;
      position: absolute;
      left: 0px;
      top: 50%;
      background: #bcbcbc;
      border-radius: 0px 1px 1px 0px;
      transform: translate(0%, -50%);

      &.success {
        background: #00cb91;
      }
    }
  }
  :deep .table-docs {
    min-width: 100%;
    min-height: calc(100%) !important;
    padding-bottom: 0 !important;
  }
  .table-inner {
    display: flex;
    flex: 1;
  }

  :deep #table-docs {
    tr {
      cursor: pointer;
    }
  }

  :deep th {
    padding: 10px 16px !important;
  }

  :deep .order-list {
    max-height: 0 !important;
  }

  .bottom-descriprion {
    font-size: 12px;
    color: #888888;
  }
  @media (max-height: 700px) {
    :deep .table-docs {
      max-height: 100% !important;
    }
  }
</style>
