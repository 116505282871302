var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "order-list"
    }
  }, [_c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('documents-header', {
    attrs: {
      "page_type": "order"
    },
    on: {
      "date_range": _vm.updateDateRange
    }
  }), _c('div', {
    staticClass: "b-table__content"
  }, [_c('documents-table-filter', {
    ref: "=doc_table_filter",
    attrs: {
      "table_name": "orders-docs",
      "placeholder": "Поиск"
    },
    on: {
      "handler_pagination": _vm.handlerSearch,
      "clear": _vm.clearSearchStr
    }
  }), _c('resizable-table', {
    staticClass: "my-class",
    attrs: {
      "inside_card": false,
      "table_name": "order-list",
      "empty_text": "Ничего не найдено",
      "items": _vm.items,
      "default_fields": _vm.fields,
      "busy": _vm.$apollo.queries.OrderHeads.loading
    },
    on: {
      "row-click": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox', {
          on: {
            "change": _vm.setSelected
          },
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var item = _ref.item,
            value = _ref.value;
        return [_c('div', {
          staticClass: "b-status",
          class: {
            success: item.fixed
          }
        }), _c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-checkbox', {
          attrs: {
            "value": value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })], 1)];
      }
    }, {
      key: "body_date",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "b-date text-left"
        }, [_vm._v(_vm._s(item.number))]), _c('div', {
          staticClass: "b-date date mt-1 table-substr text-left"
        }, [_vm._v(" " + _vm._s(item.date ? _vm.formatDate(item.date) : '') + " ")])];
      }
    }, {
      key: "body_supplier",
      fn: function (data) {
        var _data$item$supplier, _data$item$supplier2, _data$item$supplier3, _data$item$supplier4, _data$item$supplier5, _data$item$supplier6;

        return [_c('div', {
          staticClass: "b-supplier name cursor text-left"
        }, [_vm._v(" " + _vm._s((_data$item$supplier = data.item.supplier) !== null && _data$item$supplier !== void 0 && _data$item$supplier.full_name ? (_data$item$supplier2 = data.item.supplier) === null || _data$item$supplier2 === void 0 ? void 0 : _data$item$supplier2.full_name : (_data$item$supplier3 = data.item.supplier) !== null && _data$item$supplier3 !== void 0 && _data$item$supplier3.name ? (_data$item$supplier4 = data.item.supplier) === null || _data$item$supplier4 === void 0 ? void 0 : _data$item$supplier4.name : 'Не указан') + " ")]), _c('div', {
          staticClass: "b-supplier info table-substr text-left"
        }, [_vm._v(" ИНН/КПП: "), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s((_data$item$supplier5 = data.item.supplier) !== null && _data$item$supplier5 !== void 0 && _data$item$supplier5.inn ? data.item.supplier.inn : '–') + "/ " + _vm._s((_data$item$supplier6 = data.item.supplier) !== null && _data$item$supplier6 !== void 0 && _data$item$supplier6.kpp ? data.item.supplier.kpp : '–') + " ")])])];
      }
    }, {
      key: "body_fixed",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('b-badge', {
          class: {
            success: item.fixed
          }
        }, [_vm._v(" " + _vm._s(item.fixed ? 'Проведен' : 'Черновик') + " ")])];
      }
    }, {
      key: "body_sum",
      fn: function (_ref4) {
        var value = _ref4.value;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")])];
      }
    }, {
      key: "body_new_sum",
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")])];
      }
    }, {
      key: "body_retail_sum",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(item.retail_sum) + " ₽ ")])];
      }
    }, {
      key: "body_supply_date",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(_vm.formatDate(item.supply_date)) + " ")])];
      }
    }, {
      key: "body_owner",
      fn: function (_ref8) {
        var _item$owner$last_name, _item$owner, _item$owner$first_nam, _item$owner2;

        var item = _ref8.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s((_item$owner$last_name = (_item$owner = item.owner) === null || _item$owner === void 0 ? void 0 : _item$owner.last_name) !== null && _item$owner$last_name !== void 0 ? _item$owner$last_name : '') + " " + _vm._s((_item$owner$first_nam = (_item$owner2 = item.owner) === null || _item$owner2 === void 0 ? void 0 : _item$owner2.first_name) !== null && _item$owner$first_nam !== void 0 ? _item$owner$first_nam : '') + " ")])];
      }
    }, {
      key: "body_status",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [item.status !== 'going' ? _c('div', {
          staticStyle: {
            "width": "100%",
            "display": "flex",
            "justify-content": "center"
          }
        }, [_c('div', {
          staticClass: "collumn-set",
          staticStyle: {
            "border": "1px solid #00cb91",
            "border-radius": "3px",
            "padding": "4px 12px",
            "color": "#00cb91"
          }
        }, [_vm._v(" Отправлен ")])]) : _c('div', {
          staticStyle: {
            "width": "100%",
            "display": "flex",
            "justify-content": "center"
          }
        }, [_c('div', {
          staticClass: "collumn-set",
          staticStyle: {
            "border": "1px solid #888888",
            "border-radius": "3px",
            "padding": "4px 12px",
            "color": "#888888"
          }
        }, [_vm._v(" Не отправлен ")])])];
      }
    }, {
      key: "body_total",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "justify-content": "space-around",
            "align-items": "center"
          }
        }, [_c('span', {
          staticStyle: {
            "flex": "1"
          }
        }, [_vm._v(_vm._s(item.sum) + " ")]), _c('span', {
          staticStyle: {
            "flex": "1"
          }
        }, [_vm._v(_vm._s(item.retail_sum) + " ")])])];
      }
    }])
  }), _c('table-navbar', {
    staticStyle: {
      "margin": "-25px 0 0"
    },
    attrs: {
      "doc_type": "order",
      "items": _vm.selected,
      "clear_selected": _vm.clearSelected,
      "items_name": "documents"
    },
    on: {
      "remove_items": _vm.removeHeads,
      "print": _vm.printHeads,
      "createIncoming": _vm.createIncoming
    }
  })], 1)], 1), _c('pagination', {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      "total": _vm.total,
      "table_name": "orders-docs"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }